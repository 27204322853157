<template>
  <div>
    <div ref="navBar">
      <UserAdministrationNavBar />
    </div>
    <b-badge class="w-100" variant="secondary" show size="sm"
      >User Administration</b-badge
    >

    <b-container class="mt-2" fluid>
      <b-tabs pills vertical>
        <b-tab title="‣ Users">
          <b-form @submit.prevent="filterData" @reset="resetFilters">
            <b-row class="my-2">
              <b-col cols="auto">
                <h1 class="text-white">
                  <b-icon icon="person-fill"></b-icon> Users
                </h1>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col>
                <b-row>
                  <b-col class="px-1"
                    ><b-input-group>
                      <b-form-input
                        v-model="filters.username"
                        placeholder="Filter by Username"
                        type="search"
                      ></b-form-input> </b-input-group
                  ></b-col>
                  <b-col class="px-1"
                    ><b-input-group>
                      <b-form-input
                        v-model="filters.email"
                        placeholder="Filter by Email"
                        type="search"
                      ></b-form-input> </b-input-group
                  ></b-col>
                  <b-col class="px-1"
                    ><b-input-group>
                      <b-form-input
                        v-model="filters.firstName"
                        placeholder="Filter by First Name"
                        type="search"
                      ></b-form-input> </b-input-group
                  ></b-col>
                  <b-col class="px-1"
                    ><b-input-group>
                      <b-form-input
                        v-model="filters.lastName"
                        placeholder="Filter by Last Name"
                        type="search"
                      ></b-form-input> </b-input-group></b-col
                  ><b-col class="px-1"
                    ><b-input-group>
                      <b-form-input
                        v-model="filters.tenant"
                        placeholder="Filter by Tenants"
                        type="search"
                      ></b-form-input> </b-input-group
                  ></b-col>
                  <b-col class="pl-1 pr-3"
                    ><b-input-group>
                      <b-form-input
                        placeholder="Filter by mobile number"
                        type="number"
                        v-model="filters.mobileNumber"
                      ></b-form-input> </b-input-group
                  ></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2" align-h="between">
              <b-col>
                <b-row>
                  <b-col class="px-1" cols="auto"
                    ><b-button type="reset"
                      >Reset Filters</b-button
                    ></b-col
                  ><b-col class="px-1" cols="auto"
                    ><b-button type="submit" variant="primary" 
                      >Apply Filters</b-button
                    ></b-col
                  >
                </b-row>
              </b-col>
              <b-col cols="auto"
                ><b-button @click="onAddNewUser" title="Add new user"
                  ><b-icon icon="plus-circle"></b-icon> New User</b-button
                ></b-col
              >
            </b-row>
            <b-row class="mt-4">
              <b-table
                striped
                class="white-font"
                :fields="tableFields"
                :items="tableFetchedUsers"
                :filter="filter"
                :filter-included-fields="filterOn"
                :busy="isBusy"
                aria-controls="users-table"
                :per-page="perPage"
                :current-page="currentPage"
                 :sort-by="'firstName'"
                  :sort-desc="false"
              >
                <template #table-busy>
                  <div class="text-center text-white my-2 mt-3">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <template v-if="row.item.id != userId">
                     <b-button 
                    size="sm"
                    @click="editUser(row.item, row.index, $event.target)"
                    class="mr-1"
                    title="Edit user"
                  >
                    <b-icon icon="pencil-fill"></b-icon>
                  </b-button>
                  </template>
                 
                  <b-button
                    size="sm"
                    @click="cloneUser(row.item, row.index, $event.target)"
                    class="mr-1"
                    title="Clone user"
                  >
                    <b-icon icon="people-fill"></b-icon>
                  </b-button>
                  <template v-if="row.item.id != userId"
                    ><b-button
                      size="sm"
                      @click="deleteUser(row.item, row.index, $event.target)"
                      class="mr-1"
                      title="Delete user"
                    >
                      <b-icon icon="trash-fill"></b-icon> </b-button
                  ></template>
                    <template v-if="row.item.id != userId">
                    <b-form-checkbox
                      @change="changeUserState(row.item)"
                      :id="row.item.id"
                      v-model="row.item.state"
                      name="user-state"
                      value="Active"
                      unchecked-value="Inactive"
                    >
                      <div>
                        Status: <strong>{{ row.item.state }}</strong>
                      </div>
                    </b-form-checkbox>
                  </template>
                </template>

                <template #cell(tenants)="data">
               
                  
                      <div
                        v-for="(tenant, index) in data.item.tenants"
                        :key="index"
                      >
                        <b-row>
                          {{ tenant}}
                        </b-row>
                      </div>
                    
                 
                </template>
              </b-table>
            </b-row>
            <template v-if="totalRows > 10">
            
              <div class="m-0 p-0" ref="paginationControls">
                <b-navbar type="dark" variant="dark" class="px-0">
                  <b-nav-form>
                    <b-input-group
                      :prepend="'Total: ' + totalRows + ' Show:'"
                      size="sm"
                    >
                      <b-form-select
                        v-model="perPage"
                        :options="perPageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-input-group>
                  </b-nav-form>
                  <b-navbar-nav v-if="perPage > 0" >
                    <b-pagination
                      class="ml-2"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      :limit="5"
                      :first-number="true"
                      :last-number="true"
                    >
                    </b-pagination>
                  </b-navbar-nav>
                </b-navbar>
              </div>
            </template>
          </b-form>
        </b-tab>
        <!-- <b-tab title="‣ Groups">
          <b-form>
            <b-row class="my-2">
              <b-col cols="auto">
                <h1 class="text-white">
                  <b-icon icon="people-fill"></b-icon> Groups
                </h1>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col cols="auto"
                ><b-input-group>
                  <b-form-input></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col cols="auto"
                ><b-button class="mr-2">New</b-button
                ><b-button class="mr-2">Edit</b-button
                ><b-button class="mr-2">Cut</b-button
                ><b-button class="mr-2">Paste</b-button
                ><b-button class="mr-2">Delete</b-button></b-col
              >
            </b-row>
          </b-form>
        </b-tab> -->
      </b-tabs>
    </b-container>

    <!-- User Modals (Delete) -->

    <UserDeleteDialogue
      :deleteUserInfo="deleteUserModal"
      :resetDeleteUserModal="resetDeleteUserModal"
      :user="selectedActionUser"
      @on-delete="reloadUser"
    />
  </div>
</template>

<script>
import Vue from "vue";
import permissions from "../common/permissions";
import UserAdministrationNavBar from "./UserAdministrationNavBar.vue";
import userAdministrationWebServices from "../common/userAdministrationWebServices";

import UserDeleteDialogue from "./UserDeleteDialogue.vue";

export default {
  name: "UserAdministration",
  components: {
    UserAdministrationNavBar,

    UserDeleteDialogue,
  },
  data() {
    return {
      perPageOptions: [
        { value: 2, text: "2" },
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 0, text: "All" },
      ],
      createTimestamp: null,
      filters: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        tenant: "",
        mobileNumber:"",
   
      },
      perPage: 10,
      currentPage: 1,
      isBusy: false,
      userInfo: null,
      isEdit: false,
      showModal: false,
      usernameFilter: "",
      emailFilter: "",
      firstNameFilter: "",
      lastNameFilter: "",
      tenantProfileFilter: "",
      status: "Inactive",
      tableFields: [
        {
          key: "username",
          label: "Username",
          formatter: "userName",
        },
        {
          key: "email",
          label: "Email",
          formatter: "email",
        },
        {
          key: "firstName",
          label: "First Name",
          formatter: "firstName",
        },
        {
          key: "lastName",
          label: "Last Name",
          formatter: "lastName",
        },
        {
          key: "mobileNumber",
          label: "Mobile Number",
          formatter: "mobileNumber",
        },
        {
          key: "tenants",
          label: "Tenants",
          formatter: "tenantsprofiles",
        },
        { key: "actions", label: "Actions" },
      ],
      tableFetchedUsers: [],
      addUserModal: {
        id: "add-user-modal",
        title: "",
        content: "",
      },
      editUserModal: {
        id: "edit-user-modal",
        title: "",
        content: null,
      },
      cloneUserModal: {
        id: "clone-user-modal",
        title: "",
        content: "",
      },
      deleteUserModal: {
        id: "delete-user-modal",
        title: "",
        content: "",
      },
      filter: null,
      filterOn: [],
      selectedActionUser: {},
      allUsers:[],
    };
  },
  async mounted() {
    try {
      await this.loadAllUsers();
    } catch (error) {
      Vue.$log.error("Error ",error)
    }
  },
  computed: {
    totalRows() {
      return this.tableFetchedUsers.length;
    }, 
    fields() {
      var fieldDefns = [];
      if (this.items.length > 0) {
        fieldDefns.push({
          key: "_s",
          label: "",
          sortable: false,
          thClass: "bg-secondary text-white position-sticky",
        });
        fieldDefns.push({
          key: "actions",
          label: "Actions",
          sortable: false,
          thClass: "bg-secondary text-white position-sticky",
        });
        fieldDefns.push({
          key: "_l",
          label: "Lock",
          sortable: true,
          thClass: "bg-secondary text-white position-sticky",
        });
        const keys = Object.keys(this.items[0]);
        for (var f = 0; f < keys.length; f++) {
          const key = keys[f];
          if (!key.startsWith("_")) {
            fieldDefns.push({
              key: key,
              label: key,
              sortable: true,
              thClass: "bg-secondary text-white position-sticky",
            });
          }
        }
      }
      return fieldDefns;
    },
    groups() {
      var groupsForUser = [];
      try {
        let groupsFromToken = this.$store.state.keycloak.tokenParsed["groups"];
        for (var i = 0; i < groupsFromToken.length; i++) {
          var can_do_query_search = permissions.hasPermission(
            groupsFromToken[i],
            permissions.CAN_DO_QUERY_SEARCH
          );
          if (can_do_query_search) {
            groupsForUser.push({
              display: groupsFromToken[i].substring(1),
              value: groupsFromToken[i],
            });
          }
        }
        this.$log.debug(groupsForUser);
      } catch {
        groupsForUser = [];
      }
      return groupsForUser;
    },
    locale() {
      return this.$store.state.locale;
    },
    userId() {
      return this.$store.state.keycloak.tokenParsed.sub;
    },
    activeComponent() {
      return this.$store.state.activeComponent;
    },
  },
  watch: {
    activeComponent(newVal /*, oldVal*/) {
      this.$log.debug("changing activeComponent to [" + newVal + "]");
      if (newVal == "") {
        this.split.setSizes([100, 0]);
      } else {
        if (this.$refs.navBar.clientWidth < 600) {
          this.split.setSizes([0, 100]);
        } else if (this.split.getSizes()[0] > 90) {
          this.split.setSizes([50, 50]);
        }
        if (newVal == "UserHistory") {
          this.$refs.userHistory.show();
        }
      }
    },
  },
  methods: {
   filterData(){
      this.tableFetchedUsers= this.allUsers.filter(
        (item) =>
          item.username
           .toLowerCase()
           .includes(this.filters.username.toLowerCase()) &&
          item?.email?.toLowerCase().includes(this.filters.email.toLowerCase()) &&
          item.firstName
           .toLowerCase()
           .includes(this.filters.firstName.toLowerCase()) &&
          item.lastName
           .toLowerCase()
           .includes(this.filters.lastName.toLowerCase()) && 
          item.tenants.some(tenant => tenant.toLowerCase().includes(this.filters.tenant.toLowerCase())) && item.mobileNumber.toLowerCase().includes(this.filters.mobileNumber.toLowerCase())
      );
    },
    async loadAllUsers() {
      try {
        this.isBusy = true;
        const response = await userAdministrationWebServices.fetchAllUser();
        this.tableFetchedUsers = response;
        this.allUsers=response
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        Vue.$log.debug("Error::", error);
      }
    },
    onAddNewUser() {
      this.$router.push("/create_user");
    },
    async reloadUser() {
      this.loadAllUsers();
    },
    async changeUserState(item) {
   try {
     await userAdministrationWebServices.changeUserStatus(item.id,item.state == "Active")
    await  this.loadAllUsers();
   } catch (error) {
    Vue.$log.debug("Error::", error);
   }
    },
    resetFilters() {
      this.filters = {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        tenant: "",
        mobileNumber:"",
      };
      this.loadAllUsers();
    },
    addUser() {
      this.addUserModal.title = `Add User`;
      this.$root.$emit("bv::show::modal");
    },
    async editUser(item) {
      this.$router.push(`edit_user?userId=${item.id}`);
    },
    cloneUser(item) {
      this.$router.push(`clone_user?userId=${item.id}`);
    },
    deleteUser(item, index, button) {
      this.deleteUserModal.title = `Delete User with ID ${index}`;
      this.deleteUserModal.content = `${item.username}`;
      this.selectedActionUser = item;
      this.$root.$emit("bv::show::modal", this.deleteUserModal.id, button);
    },
    resetAddUserModal() {
      this.addUserModal.title = "";
    },
    resetEditUserModal() {
      this.editUserModal.title = "";
      this.editUserModal.content = "";
    },
    resetCloneUserModal() {
      this.cloneUserModal.title = "";
      this.cloneUserModal.content = "";
    },
    resetDeleteUserModal() {
      this.deleteUserModal.title = "";
      this.deleteUserModal.content = "";
    },
    updateLock(study_iuid, lock) {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i]._study_iuid == study_iuid) {
          this.items[i]._l = lock;
        }
      }
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: "INSPIRE PACS",
        variant: variant,
      });
    },
    lockStudy(item) {
      let lock = false;
      if (
        permissions.hasPermission(item._groupId, permissions.CAN_LOCK_STUDY)
      ) {
        lock = item._l != "" && item._l != this.userId;
      }
      return lock;
    },
    rowRank(status) {
      var statusRank = 0;
      switch (status) {
        case "": // locked
          statusRank = 5;
          break;
        case "success": // final
          statusRank = 4;
          break;
        case "primary": // preliminary
          statusRank = 3;
          break;
        case "secondary": // draft
          statusRank = 2;
          break;
        case "warning": // unread
          statusRank = 1;
          break;
        case "light": // in transit
        default:
          statusRank = 0;
          break;
      }
      return statusRank;
    },
    sortCompare(
      aRow,
      bRow,
      key /*, sortDesc, formatter, compareOptions, compareLocale*/
    ) {
      if (key == "Study Date Time") {
        const aR = this.rowRank(aRow["_rowVariant"]);
        const bR = this.rowRank(bRow["_rowVariant"]);
        if (aR == bR) {
          const tsKey = "_" + key;
          const aTS = aRow[tsKey];
          const bTS = bRow[tsKey];

          return bTS.localeCompare(aTS);
        } else {
          return bR - aR;
        }
      } else if (key.endsWith("Date Time") || key.startsWith("Date of")) {
        const tsKey = "_" + key;
        const aTS = aRow[tsKey];
        const bTS = bRow[tsKey];

        return bTS.localeCompare(aTS);
      } else {
        // Fallback to default sort routine.
        //
        return false;
      }
    },
  },
};
</script>

<style scoped>
.mrTable {
  background-color: #ffdab9 !important;
}

.table {
  text-align: left;
}

.cbM {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.bottomOfPage {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

#flex {
  display: flex;
  flex-direction: row;
}

.white-font {
  color: #fff;
}
</style>
