import axios from "axios";
import Vue from "vue";
import store from "../store";

// Webservice for user Admim
async function fetchAllUser() {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin";
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        let users = [];
       
        Object.keys(data).forEach((key) => {
         let customerIds =Object.keys(data[key].customers)
         let tenants =[]
         customerIds.forEach((customerId)=>{
          Object.keys(data[key].customers[customerId].tenants).forEach(tenant=>{
            tenants.push(replaceUnderscoresWithSpace(tenant))
          })
          
         })
         
          users.push({ id: key, ...data[key] ,tenants,state: data[key].enabled ? 'Active' : 'Inactive'});
        });
        resolve(users);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function fetchAdminTenants() {
  return new Promise((resolve, reject) => {
    let url = store.state.webServicesBaseUrl + "/Tenants/admin";
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        let allTenants = [];
        Object.keys(data).forEach((key) => {
          let parent = { id: key, name: data[key].name, tenants: [] };
          let tenants = [];
          Object.keys(data[key].tenants).forEach((tenantKey) => {
            tenants.push({
              value: tenantKey,
              text: data[key].tenants[tenantKey],
              id: tenantKey,
            });
          });
          parent.tenants = tenants;
          allTenants.push(parent);
        });
        resolve(allTenants);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function createUser(data) {
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + "/Users/admin";

    axios
      .post(endpoint, data, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function fetchUserById(id) {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(id);
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function updateUser(data) {
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(data.id);

    axios
      .put(endpoint, data, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function deleteUser(id) {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(id);
    axios
      .delete(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function fetchUserWorkList(data) {
  return new Promise((resolve, reject) => {
    let usersUrl =
      store.state.webServicesBaseUrl + `/Users/admin/settings/${encodeURIComponent(data.customer_id)}/${encodeURIComponent(data.user_id)}`;
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function updateUserSetting(data){
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + `/Users/admin/settings/${encodeURIComponent(data.customer_id)}/${encodeURIComponent(data.user_id)}`

    axios
      .put(endpoint, data.setting, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function changeUserStatus(userId,status){
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + `/Users/admin/changeStatus/${encodeURIComponent(userId)}`

    axios
      .put(endpoint, {status:status}, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function fetchUserSignature(userId,customerId){
  return new Promise((resolve,reject)=>{
    let endpoint = store.state.webServicesBaseUrl+`/Users/admin/signature/${customerId}/${userId}`
    axios
      .get(endpoint, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
        responseType: 'arraybuffer'
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  })
}
async function updateSignature(userId,customerId,signatureImage, filename) {
  Vue.$log.debug("Signature Image ::::::::;   ",signatureImage)
  return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('signatureImage', signatureImage, filename)
      let endpoint = store.state.webServicesBaseUrl+`/Users/admin/signature/${customerId}/${userId}`
      axios
        .put(endpoint,formData ,{
          headers: {
            Authorization: "Bearer " + store.state.keycloak.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
  })
}
// HELPER FUNCTIONS

function replaceUnderscoresWithSpace(str) {
 // Check if the text contains two consecutive underscores
 if (str.includes('__')) {
  // Split the text into an array using two consecutive underscores as the separator
  const parts = str.split('__');

  // Return the second part with a single underscore replaced by a space
  return parts[1].replace('_', ' ');
} else {
  // If there are no two consecutive underscores, just replace any single underscores with a space
  return str.replace('_', ' ');
}
}

export default {
  // Webservice
  fetchAllUser,
  fetchAdminTenants,
  createUser,
  deleteUser,
  fetchUserById,
  updateUser,
  fetchUserWorkList,
  updateUserSetting,
  changeUserStatus,
  fetchUserSignature,
  updateSignature,
};
