<template>
  <b-modal
    :id="deleteUserInfo.id"
    title="Delete User"
    size="lg"
    header-bg-variant="secondary"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    content-class="shadow"
    hide-footer
    @hide="resetDeleteUserModalFunc"
  >
    <b-row>
      <b-col class="mx-3">
        <h4>
          Are you sure you want to delete user with
          <strong>Username: {{ deleteUserInfo.content }}</strong>
        </h4>
      </b-col>
    </b-row>
    <b-row align-h="end"
      ><b-col cols="auto">
        <b-button class="mt-3 mr-1" variant="" @click="calcelDelete" :disabled="isLoading"
          >Cancel</b-button
        >
        <b-button class="mt-3" variant="danger" @click="deleteUser" :disabled="isLoading"
          >
          <b-icon
                  v-if="isLoading"
                  icon="circle-fill"
                  animation="throb"
                  font-scale="1"
                ></b-icon>
          Delete User</b-button
        >
      </b-col></b-row
    >
  </b-modal>
</template>

<script>
import Vue from 'vue';
import userAdministrationWebServices from "../common/userAdministrationWebServices";

export default {
  props: {
    deleteUserInfo: Object,
    resetDeleteUserModal: Function,
    user: Object,
  },
  data(){
    return{
      isLoading:false,
    }
  },
  methods: {
    resetDeleteUserModalFunc() {
      if (this.resetDeleteUserModal == "Function") {
        this.resetDeleteUserModal();
      }
    },
    async deleteUser() {
      try {
      
        this.isLoading=true
        await userAdministrationWebServices.deleteUser(this.user.id);
        this.$bvToast.toast("User deleted successfully", {
        autoHideDelay: 5000,
        solid: true,
        title: "Success",
        variant: "info",
      });
      this.$bvModal.hide(this.deleteUserInfo.id);
      this.$emit("on-delete");
      this.isLoading=false
      } catch (error) {
        Vue.$log.error("Error while deleting user",error)
        this.isLoading=false
      }
    },
    calcelDelete(){
        this.$bvModal.hide(this.deleteUserInfo.id);
    }
  },
};
</script>

<style></style>
